@import url('https://fonts.googleapis.com/css2?family=IM+Fell+Great+Primer&display=swap');

* {
  font-family: 'IM Fell Great Primer', serif;
}

h1 {
  font-size: 20px;
}

.name {
  font-size: 32px;
  margin: 0;
}

.text {
  margin: 0;
  font-size: 18px;
}

.pronounce {
  font-size: 22.5px;
}

a {
  color: black;
}
.center-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 80vh;
  width: 310px;
  margin: 0 auto;
}

.definition {
  width: auto;
  margin: 0 auto;
}

.pageContainer {
  width: auto;
  max-width: 1000px;
  margin: 0 auto;
}
.footer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  margin: 0;
}


@media only screen and (min-width: 0px) and (max-width: 800px)
{
  .pageContainer {
    width: 95%;
  }

  .center-screen {
    width: 280px;
  }

  h1 {
    font-size: 18px;
  }
  
  .name {
    font-size: 28px;
    margin: 0;
  }
  
  .text {
    margin: 0;
    font-size: 16px;
  }
  
  .pronounce {
    font-size: 18px;
  }


  .center-screen {
    min-height: 70vh;
  }
}
