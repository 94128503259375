@import url(https://fonts.googleapis.com/css2?family=IM+Fell+Great+Primer&display=swap);
@import url(https://fonts.googleapis.com/css2?family=IM+Fell+Great+Primer&display=swap);
* {
  font-family: 'IM Fell Great Primer', serif;
}

h1 {
  font-size: 20px;
}

.name {
  font-size: 32px;
  margin: 0;
}

.text {
  margin: 0;
  font-size: 18px;
}

.pronounce {
  font-size: 22.5px;
}

a {
  color: black;
}
.center-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 80vh;
  width: 310px;
  margin: 0 auto;
}

.definition {
  width: auto;
  margin: 0 auto;
}

.pageContainer {
  width: auto;
  max-width: 1000px;
  margin: 0 auto;
}
.footer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  margin: 0;
}


@media only screen and (min-width: 0px) and (max-width: 800px)
{
  .pageContainer {
    width: 95%;
  }

  .center-screen {
    width: 280px;
  }

  h1 {
    font-size: 18px;
  }
  
  .name {
    font-size: 28px;
    margin: 0;
  }
  
  .text {
    margin: 0;
    font-size: 16px;
  }
  
  .pronounce {
    font-size: 18px;
  }


  .center-screen {
    min-height: 70vh;
  }
}

@-webkit-keyframes fadeIn {
  0% {
      opacity: 0;
      
    }
   
    100% {
      opacity: 1;
      
    }
}

@keyframes fadeIn {
  0% {
      opacity: 0;
      
    }
   
    100% {
      opacity: 1;
      
    }
}

* {
  font-family: 'Helvetica', 'Arial', sans-serif;
}

body {
background-size: cover;
background-repeat: no-repeat;
background-attachment: scroll;

}

.image-gallery-fullscreen-button {
  color: black;
}

.full {
  height: auto !important;
}


.pageContainer {
  width: auto;
  padding: 0;
  margin: 0;
  max-width: 80%;
  margin: 0 auto;
  min-height: 100vh;
  height: auto;
  -webkit-animation: 3s ease 0s normal forwards 1 fadeIn;
    animation: 3s ease 0s normal forwards 1 fadeIn;
    opacity: 0;
  
}

.logo {
  width: 30%;
}

.footerLogo {
  width: 15%
}

.description {
  font-weight: 200;
  width: 45%;
  margin: 0 auto;
  text-align: center;
}

.cardPadding {
  margin: 1em;
}

.cardDescription {
  font-weight: 200;
  margin: 0 auto;
  height: 120px;
}


h1 {
  font-size: 2.5em;
  margin-top: 0px;
}

.floorplanText {
  font-size: 1.8em;
}

.floorplan {
  margin-top: .5em;
  width: 100% !important;
  border:.2em solid rgba(0, 0, 0, 0.2);
  border-radius: 0.25rem;
}

p {
  font-size: 1.5em;
  margin: 0px;
}

h2 {
  font-size: 1em;
  margin: 0px;
}

.navigationContainer {
  width: 60%;
  display: flex;
}

.navigationContainer h1 {
  width: 100%;
  font-size: 2em;
  align-items: center;
  align-content: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  
  font-weight:900;

  
}

.navigationContainer h1:hover {
  color: blue !important;
}

.navigationHeader {


}

.aptContainer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
}

.aptCard {
  width: 30%;
  outline: .2em solid black;
  margin-bottom: 2em;
  border-radius: 0.25rem;
  overflow: none !important;
}

.card { border: none !important; overflow: none !important; border-radius: 0.25rem;}

a {
  text-decoration: underline !important;
}

a:hover {
  color: gray !important;
}

.amenities {
  display: inline-block
}

.card-footer {
  padding: 0;
  background-color: transparent;
  border-top: none;
}

.section {
  -webkit-animation: 3s ease 0s normal forwards 1 fadeIn;
      animation: 3s ease 0s normal forwards 1 fadeIn;
}

.FAQ {
  width: 60%;
  margin: 0 auto;
}


.adjustment {
  height: 400px;
}

img.image-gallery-image {
  /*height: 300px;*/
  height: 100%;
  background-color: white;
}

.image-gallery-bullets .image-gallery-bullet {
  border: 1px solid rgba(0, 0, 0, 0.5);;
}

.image-gallery-bullets .image-gallery-bullet.active {
  border: 1px solid rgba(0, 0, 0, 0.5);
  background: rgba(0, 0, 0, 0.5);
}

form {
  margin: 0 auto;
  width: 30%;
}

input, textarea {
  margin-bottom: 1em;
  width: 100%;
  padding-left: .25em;
  border-radius: .25em;
  border:2px solid gray !important;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 0px;
  padding-left: .25em;
  border-radius: .25em;
  background-color: transparent;
  color: black;
  border:2px solid gray !important;
 
}

option {
  padding-left: .25em;
  
}

textarea {
  height: 150px;
  border-radius: .25em;
}

#fname {
  margin-top: 0em;
}

#submit {
  width: 100%;
  color: black;
  background-color: transparent;
  text-align: center;
  margin-bottom: 1em;
  border-radius: .25em;
}

select {
  margin-bottom: 1em;
  width: 60%;
  border-radius: .25em;
}

.image-gallery-content.fullscreen {
  margin-top: 0vh;
  margin: 0 auto;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.image-gallery-fullscreen-button .image-gallery-svg, .image-gallery-play-button .image-gallery-svg {
  height: 50px;
  width: 50px;
  color: rgb(179, 221, 242);
}



@media only screen and (min-width: 1px) and (max-width: 1200px)
{
.navigationContainer {
  width: 100%;
  display: inline-block;

}

.image-gallery-content.fullscreen {
  margin-top: 0vh;
}
}

@media only screen and (min-width: 701px) and (max-width: 900px)
{

  .image-gallery-content.fullscreen {
    margin-top: 0vh;
  }
 
  select {
    margin-bottom: 1em;
    width: 70%;
    border-radius: .25em;
  }
  

  .aptCard {
    width: 47%;
  }
  .aptContainer {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: stretch;
  }
}
@media only screen and (min-width: 0px) and (max-width: 700px)
{

  form {
    margin: 0 auto;
    width: 60%;
  }
  
  input, textarea {
    margin-bottom: 1em;
    width: 100%;
  }
  
  textarea {
    height: 150px;
  }

  .pageContainer {
    width: 100%;
    padding: 0;
    margin: 0;
    max-width: 100%;
    margin: 0 auto;
    min-height: 100vh;
    height: auto;
    -webkit-animation: 3s ease 0s normal forwards 1 fadeIn;
      animation: 3s ease 0s normal forwards 1 fadeIn;
    
  }

  .navigationContainer {
    width: 100%;
    display: inline-block;

  }
  
  .logo {
    width: 80%;}

    .footerLogo {
      width: 40%;
    }

  .description {
    width: 80%;
  }

  .FAQ {
    width: 80%;
  }
  
  h1 {
    font-size: 2em;
  }

  .floorplanText {
    font-size: 1.5em;
  }

  .navigationContainer h1 {
    width: 100%;
    font-size: 2em;
    align-items: center;
    align-content: center;
    justify-content: center;
  }

 
 
  p {
    font-size: 1.25em;
  }

  h2 {
    font-size: 1em;
  }

  .aptCard {
    width: 90%;
  }

  .aptContainer {
    width: 100%;
    justify-content: space-evenly;
    
  }

}

